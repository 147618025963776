import { LoginPage, LogoutPage, NoAccess, SettingsPage, SignUp } from '@moveup/app-core/views';

const OrganizationsDashboard = () => import('@/views/OrganizationsDashboard.vue');
const OrganizationsList = () => import('@/views/OrganizationsList.vue');
const OrganizationNew = () => import('@/views/OrganizationNew.vue');
const OrganizationEditUrls = () => import('@/views/OrganizationEditUrls.vue');

const OrganizationEditBasicInfo = () => import('@/views/OrganizationEditBasicInfo');
const OrganizationEditAssociations = () => import('@/views/OrganizationEditAssociations');
const OrganizationEditCommunication = () => import('@/views/OrganizationEditCommunication.vue');
const OrganizationEditCareTeams = () => import('@/views/OrganizationEditCareTeams.vue');
const OrganizationEditConsents = () => import('@/views/OrganizationEditConsents.vue');

import { checkAuth } from '@moveup/app-core/auth';

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
		props: { startPage: '/organizations' },
	},
	{
		path: '/logout',
		name: 'Logout',
		component: LogoutPage,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
		props: { name: 'Organization Admin' },
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: OrganizationsDashboard,
		beforeEnter: checkAuth,
	},
	{
		path: '/organizations',
		name: 'Organizations',
		component: OrganizationsList,
		beforeEnter: checkAuth,
	},
	{
		path: '/organizations/page/:page',
		name: 'OrganizationsPage',
		component: OrganizationsList,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id',
		redirect: '/organization/:id/basic-info',
	},
	{
		path: '/organization/:id/basic-info',
		name: 'OrganizationEditBasicInfo',
		component: OrganizationEditBasicInfo,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id/associations',
		name: 'OrganizationEditAssociations',
		component: OrganizationEditAssociations,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id/urls',
		name: 'OrganizationEditUrls',
		component: OrganizationEditUrls,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id/communication',
		name: 'OrganizationEditCommunication',
		component: OrganizationEditCommunication,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id/careteams',
		name: 'OrganizationEditCareTeams',
		component: OrganizationEditCareTeams,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization/:id/consents',
		name: 'OrganizationEditConsents',
		component: OrganizationEditConsents,
		beforeEnter: checkAuth,
	},
	{
		path: '/organization',
		name: 'OrganizationNew',
		component: OrganizationNew,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: SettingsPage,
		beforeEnter: checkAuth,
	},
];

export default routes;
