import { mapActions, mapState } from 'vuex';

const languageMixins = {
	data() {
		return {
			availableLanguages: {},
		}
	},
	computed: {
		...mapState('app', ['languages']),
		availableLanguageKeys() {
			return Object.keys(this.availableLanguages);
		},
	},
	async created() {
		await this.loadLanguages();
	},
	methods: {
		async loadLanguages(){
			if(this.languages.length > 0){
				let availableLanguages = {};
				this.languages.forEach(language=>{
					availableLanguages[language.code] = this.$gettext(language.name);
				});
				this.availableLanguages = availableLanguages;
			}
		},
	}
}

const app = {
	computed: {
		...mapState('auth', ['user', 'tokens']),
		isTokenValid() {
			console.log('Date.now() / 1000:', Date.now() / 1000)
			console.log('this.user?.exp:', this.user?.exp)
			return (Date.now() / 1000) < this.user?.exp;
		},
		hasRefreshToken() {
			console.log('this.tokens?.refreshToken:', this.tokens?.refreshToken);
			return !!this.tokens?.refreshToken;
		}
	},
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'moveUP Organization Admin',
		// all titles will be injected into this template
		titleTemplate: '%s | moveUP Organization Admin',
	},
	async created() {
		if (!this.isTokenValid && this.hasRefreshToken) await this.getRefreshToken();
		if (this.isTokenValid) await this.refreshAll();
	},
	methods: {
		...mapActions('app', ['refreshAll']),
		...mapActions('auth', ['getRefreshToken']),
	},
}

export { languageMixins, app };
