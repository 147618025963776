import Fuse from 'fuse.js';
import { api } from '@moveup/app-core';
require('json.date-extensions');

const axiosJSONDateParseParams = {
	transformResponse: data => JSON.parseWithDate(data),
};

const newOrganization = {
	active: true,
	address: [{}],
	alias: undefined,
	contact: [],
	identifier: [],
	type: [{}],
	name: undefined,
	partOf: undefined,
	telecom: [
		{ system: 'email' },
		{ system: 'phone' },
	],
	moveup: {
		activityDefinitions: [],
		carePlans: [],
		careTeams: [],
		communication: [],
		informedConsents: [],
		patients: [],
		physiotherapists: [],
		preferredLanguage: undefined,
		responsiblePhysicalTherapist: undefined,
		studies: [],
		surgeons: [],
		widePatientAccess: false,
	}
};

const initialState = {
	organizations: [],
	emailTemplates: [],
	surgeons: [],
	physioTherapists: [],
	administrativePersonnel: [],
	carePlans: [],
	activityDefinitions: [],
	allConsents: [],
	allStudies: [
		'Evolution',
		'Evolution 2',
		'Sphere',
		'Geko',
		'Discharge',
		'Gladiator',
		'Convention',
		'Rosa',
		'Pixee',
		'Dr. Vles',
		'Navio',
		'Pre-assessment',
		'Microport',
		'Zimmer Biomet',
		'PROM',
		'VLAIO',
		'Rosa Persona MC'
	],
	editOrganization: undefined,
	currentlyRefreshing: [],
	lastRefresh: undefined,
	fuse: undefined,
	languages: [],
};

const store = {
	state: initialState,
	mutations: {
		refreshOrganizations(state, organizations = []) {
			state.organizations = organizations;
			const options = {
				keys: ['name'],
				includeScore: true,
				ignoreLocation: true,
				includeMatches: true,
				distance: 10,
				threshold: 0.5
			};
			const fuse = new Fuse(organizations, options);
			state.fuse = fuse;
		},
		refreshEmailTemplates(state, emailTemplates = []) {
			state.emailTemplates = emailTemplates;
		},
		refreshSurgeons(state, surgeons = []) {
			state.surgeons = surgeons;
		},
		refreshPhysiotherapists(state, physioTherapists = []) {
			state.physioTherapists = physioTherapists;
		},
		refreshCarePlans(state, carePlans = []) {
			state.carePlans = carePlans;
		},
		refreshActivityDefinitions(state, activityDefinitions = []) {
			state.activityDefinitions = activityDefinitions;
		},
		refreshAdministrativePersonnel(state, administrativePersonnel = []) {
			state.administrativePersonnel = administrativePersonnel;
		},
		setEditOrganization(state, organization = newOrganization) {
			state.editOrganization = organization;
		},
		updateOrganizationInList(state, organization) {
			if (!organization) return;
			const index = state.organizations.findIndex(o => o._id === organization._id);
			if (index !== -1) state.organizations.splice(index, 1, organization);
			else {
				state.organizations.push(organization);
				state.organizations.sort((a, b) => a.name > b.name);
			};
		},
		refreshInformedConsents(state, consents) {
			state.allConsents = consents;
		},
		setLastRefreshTime(state, value) {
			state.lastRefresh = value;
		},
		addToCurrentlyRefreshing(state, value) {
			state.currentlyRefreshing.push(value);
		},
		removeFromCurrentlyRefreshing(state, value) {
			state.currentlyRefreshing = state.currentlyRefreshing.filter(v => v !== value);
		},
		removeAllFromCurrentlyRefreshing(state) {
			state.currentlyRefreshing = [];
		},
		refreshLanguages(state, languages) {
			state.languages = languages;
		}
	},
	getters: {
		getOrganizationNames: state => state.organizations.map(({ _id, name }) => ({ _id, name })),
		getOrganizationById: state => id => state.organizations.find(o => o._id === id),
		editOrganizationSurgeons: state => state.editOrganization?.moveup?.surgeons?.map(
			id => state.surgeons?.find(item => item._id === id)
		)?.filter(n => n),
		editOrganizationAllSurgeons: state => state.surgeons
			?.filter(item => (item?.profile?.hospital || []).includes(state?.editOrganization?._id))
			?.filter(n => n),
		editOrganizationPhysioTherapists: state => state.editOrganization?.moveup?.physiotherapists?.map(
			id => state.physioTherapists?.find(item => item._id === id)
		)?.filter(n => n),
		editOrganizationAllPhysioTherapists: state => state.physioTherapists
			?.filter(item => (item?.profile?.hospital || []).includes(state?.editOrganization?._id))
			?.filter(n => n),
		editOrganizationAllAdministrativePersonnel: state => state.administrativePersonnel
			?.filter(item => (item?.profile?.hospital || []).includes(state?.editOrganization?._id))
			?.filter(n => n),
		editOrganizationAdministrativePersonnel: state => state.administrativePersonnel,
		editOrganizationCarePlans: state => state.editOrganization?.moveup?.carePlans?.map(
			id => state.carePlans?.find(item => item._id === id)
		)?.filter(n => n),
		editOrganizationActivityDefinitions: state => state.editOrganization?.moveup?.activityDefinitions?.map(
			ad => state.activityDefinitions?.find(item => item._id === ad.limb)
		)?.filter(n => n),
		editOrganizationCareTeams: state => state.editOrganization?.moveup?.careTeams?.filter(n => n),
		editOrganizationStudies: state => state.editOrganization?.moveup?.studies?.filter(n => n),
		editOrganizationConsents: state => state.editOrganization?.moveup?.informedConsents?.filter(n => n),
		editOrganizationAffiliations: state => state.editOrganization?.moveup?.affiliations?.filter(n => n),
		isRefreshing: state => state.currentlyRefreshing.length > 0,
		isRefreshingOrganizations: state => state.currentlyRefreshing.includes('refreshOrganizations'),
		languages: state => state.languages,
	},
	actions: {
		// APP-SPECIFIC CALLS TO THE GATEWAY
		async handleRefreshCall({ commit }, { path, params, caller }) {
			console.log(caller);
			commit('addToCurrentlyRefreshing', caller);
			try {
				const result = await api.get(path, params, axiosJSONDateParseParams);
				if (result.results) commit(caller, result.results.map(Object.freeze));
				console.log(`${caller} result:`, result.total);
				return result;
			} catch (err) { console.error(err); } finally {
				commit('removeFromCurrentlyRefreshing', caller);
			}
		},

		refreshOrganizations: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshOrganizations',
			path: 'organizations',
			params,
		}),

		refreshEmailTemplates: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshEmailTemplates',
			path: 'mails',
			params,
		}),

		refreshSurgeons: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshSurgeons',
			path: 'practitioners',
			params: Object.assign({ type: 'SURGEON' }, params || {}),
		}),

		refreshPhysiotherapists: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshPhysiotherapists',
			path: 'practitioners',
			params: Object.assign({ type: 'HCP' }, params || {}),
		}),

		refreshAdministrativePersonnel: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshAdministrativePersonnel',
			path: 'practitioners',
			params: Object.assign({ type: 'ADMIN' }, params || {}),
		}),

		refreshCarePlans: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshCarePlans',
			path: 'careplans',
			params,
		}),

		refreshActivityDefinitions: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshActivityDefinitions',
			path: 'activitydefinitions',
			params,
		}),

		refreshInformedConsents: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshInformedConsents',
			path: 'consents',
			params,
		}),

		refreshLanguages: async ({ dispatch }, params) => await dispatch('handleRefreshCall', {
			caller: 'refreshLanguages',
			path: 'languages',
			params,
		}),

		setEditOrganization({ commit }, organization) {
			commit('setEditOrganization', organization);
		},

		deleteOrganizations(_, params) {
			return api.del('organizations', params);
		},

		async createOrganization({ commit }, params) {
			const result = await api.post('organizations', { resourceType: 'Organization', ...params });
			if (result) commit('updateOrganizationInList', result);
			return result;
		},

		async updateOrganization({ commit }, { _id: id, ...params }) {
			const result = await api.patch(`organizations/${id}`, { resourceType: 'Organization', ...params });
			if (result) commit('updateOrganizationInList', result);
			return result;
		},

		createNewEditOrganization({ commit }) {
			console.log('newOrganization:', JSON.stringify(newOrganization, null, 2));
			commit('setEditOrganization', JSON.parse(JSON.stringify(newOrganization)));
		},

		countOrganizations(_, params) {
			return api.get('organizations/count', params);
		},

		getOrganization(_, id) {
			return api.get(`organizations/${id}`, null, axiosJSONDateParseParams);
		},

		getCareTeam(_, params) {
			if (typeof params === 'object') return api.get(`careteams`, params, axiosJSONDateParseParams);
			return api.get(`careteams/${params}`, null, axiosJSONDateParseParams);
		},

		getCareTeamAffiliates(_, organizationId) {
			return api.get(`careteams/affiliates/${organizationId}`, null, axiosJSONDateParseParams);
		},

		updateOrganizationCareTeam(_, { organizationId, careTeamId, params }) {
			return api.patch(`organizations/${organizationId}/careteams/${careTeamId}`, params);
		},

		updateCareTeam(_, careTeam) {
			if (!careTeam?._id) return;
			return api.patch(`careteams/${careTeam._id}`, careTeam);
		},

		createCareTeam(_, careTeam) {
			return api.post(`careteams`, careTeam);
		},

		getOrganizationAffiliations(_, organizationId) {
			return api.get(`organizations/${organizationId}/affiliates`, null, axiosJSONDateParseParams);
		},

		updateOrganizationAffiliations(_, { _id, affiliates }) {
			return api.patch(`organizations/${_id}/affiliates`, affiliates);
		},

		createOrganizationAffiliations(_, { _id, affiliates }) {
			return api.post(`organizations/${_id}/affiliates`, affiliates);
		},

		async refreshAll({ commit, dispatch, state }) {
			console.log('state.currentlyRefreshing:', state.currentlyRefreshing);
			commit('removeAllFromCurrentlyRefreshing');
			if (state.currentlyRefreshing.length === 0) {
				console.log('🔄 refreshAll 🔄');
				try {
					await dispatch('refreshOrganizations', { sortBy: 'name' });
				} catch (err) {
					console.error(err);
					console.log('Retrying...');
					await dispatch('refreshOrganizations', { sortBy: 'name' });
				} finally {
					const promises = [
						dispatch('refreshEmailTemplates'),
						dispatch('refreshSurgeons'),
						dispatch('refreshPhysiotherapists'),
						dispatch('refreshAdministrativePersonnel'),
						dispatch('refreshCarePlans'),
						dispatch('refreshInformedConsents'),
						dispatch('refreshActivityDefinitions'),
						dispatch('refreshLanguages'),
					];
					commit('setLastRefreshTime', Date.now());
					return Promise.all(promises);
				}
			}
		},
	},
};

export default store;
